















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { D3PieChart } from 'vue-d3-charts';
import { TranslateResult } from 'vue-i18n';

interface ChartInterface {
  name: string | TranslateResult;
  total: number;
  color: string;
}

@Component({
  components: {
    D3PieChart,
  },
})
export default class QuizFacilitatorSummary extends Vue {
  @Prop(Object)
  taskCountStates!: Record<'correct' | 'incorrect' | 'partlyCorrect' | 'undecided' | 'total', number>

  @Prop(String)
  correctPercent!: string;

  chartConfig = {
    key: 'name',
    value: 'total',
    color: {
      key: 'color',
    },
    radius: {
      inner: 100,
    },
  }

  twoDigit (number: number): string {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }

  get chartData (): ChartInterface[] {
    const {
      correct,
      incorrect,
      partlyCorrect,
      undecided,
    } = this.taskCountStates;

    const response: ChartInterface[] = [
      {
        name: this.$t('quiz.correct'),
        total: correct,
        color: 'var(--color-success)',
      },
    ];

    if (this.taskCountStates.partlyCorrect > 0) {
      response.push({
        name: this.$t('quiz.partlyCorrect'),
        total: partlyCorrect,
        color: 'var(--opaque-success)',
      });
    }

    if (this.taskCountStates.incorrect > 0) {
      response.push({
        name: this.$t('quiz.incorrect'),
        total: incorrect,
        color: 'var(--color-error)',
      });
    }

    if (this.taskCountStates.undecided > 0) {
      response.push({
        name: this.$t('quiz.undecided'),
        total: undecided,
        color: 'var(--color-grey)',
      });
    }

    return response;
  }
}
